<template>
    <nav class="navbar navbar-expand navbar-light bg-white osahan-nav-top p-0 px-2 sticky-top" style="margin-left: 0 !important;">
        <div class="container" style="padding: 0 5px;">
            <router-link to="/" class="navbar-brand mr-2">
                <!-- <img src="../../assets/image/SudaniWhiteLogo-NoShadow.png" alt=""> -->
            </router-link>
            <!-- <template v-if="showHeader">
                    
                </template>
                <template v-else> -->
                    
                    <!-- <li class="nav-item dropdown no-arrow  ">
                        <router-link to="/Login" style="background: #044871 !important;color: #fff;border: 1px solid #83cbce;border-radius: 5px;padding: 2px 15px 0 15px;" class="btn btn-sm header-btn btn-outline-dark ml-auto pointer" >دخول</router-link>
                    </li> -->
                    <div class="nav-item dropdown no-arrow">
                        <a style="background: rgb(0, 0, 0) !important; color: rgb(255, 255, 255); border: 1px solid rgb(255, 255, 255); border-radius: 5px;" @click="gotoSpay()" class="btn btn-sm header-btn btn-outline-dark ml-auto pointer mar-15" >إشتــراك</a>
                    </div>
                <!-- </template> -->
            <form style="margin-left: 25px !important;" class="d-none d-sm-inline-block form-inline mr-auto my-2 my-md-0 mw-100 navbar-search">
                <div class="input-group">
                    <input type="text" class="form-control shadow-none border-0" placeholder="بحث ..." aria-label="Search" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                        <button class="btn btn-dark" type="button">
                            <i class="feather-search"></i>
                        </button>
                    </div>
                </div>
            </form>
            <ul class="navbar-nav ml-auto d-flex align-items-center">
                <li class="nav-item dropdown no-arrow d-sm-none">
                    <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="feather-search mr-2"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right p-3 shadow-sm animated--grow-in" aria-labelledby="searchDropdown">
                        <form class="form-inline mr-auto w-100 navbar-search">
                            <div dir="rtl" class="input-group">
                                <input type="text" style="margin-right: 5px;" class="form-control border-0 shadow-none" placeholder="بحث ..." aria-label="Search" aria-describedby="basic-addon2">
                                <div class="input-group-append">
                                    <button class="btn" type="button" style="border-radius: 12px 0 0 12px;">
                                        <i class="feather-search"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </li>
                <!-- <template v-if="showHeader">
                    <li class="nav-item dropdown no-arrow  ">
                        <router-link to="/Unsubscribes" style="background: #044871 !important;color: #fff;border: 1px solid #83cbce;border-radius: 5px;padding: 2px 15px 0 15px;" class="btn btn-sm header-btn btn-outline-dark ml-auto pointer" >الغاء الاشتراك</router-link>
                    </li>
                </template>
                <template v-else> -->
                    <li class="nav-item dropdown no-arrow  ">
                        <a style="padding-right: 0;" class="nav-link dropdown-toggle" href="#" id="searchDropdown22" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i style="font-size: 21px;" class="feather-user mr-2"></i>
                        </a>

                        <div class="dropdown-menu dropdown-menu-right p-3 shadow-sm animated--grow-in" aria-labelledby="searchDropdown22">
                            <form class="form-inline mr-auto w-100 navbar-search">
                                <div dir="rtl" class="input-group">
                                    <h6 style="background: transparent; min-height: 35px; font-size: 14px; font-weight: 600; color: rgb(0, 0, 0); text-align: right;" class="form-control border-0 shadow-none">  سجل دخولك  وتمتع بتسلية فريدة من الالعاب</h6>
                                    <div class="input-group-append">
                                       <router-link to="/Login" style="background: rgb(0, 0, 0) !important; color: rgb(255, 255, 255); border: 1px solid rgb(251, 251, 251); border-radius: 5px; padding: 2px 15px 0px;" class="btn btn-sm header-btn btn-outline-dark ml-auto pointer" >دخول</router-link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </li>
                    <!-- <li class="nav-item dropdown no-arrow  ">
                        <router-link to="/Login" style="background: #044871 !important;color: #fff;border: 1px solid #83cbce;border-radius: 5px;padding: 2px 15px 0 15px;" class="btn btn-sm header-btn btn-outline-dark ml-auto pointer" >دخول</router-link>
                    </li> -->
                    <!-- <li class="nav-item dropdown no-arrow  ">
                        <a style="background: #044871 !important;color: #fff;border: 1px solid #83cbce;border-radius: 5px;padding: 2px 15px 0 15px;" @click="gotoSpay()" class="btn btn-sm header-btn btn-outline-dark ml-auto pointer" >إشتــراك</a>
                    </li> -->
                <!-- </template> -->
                    <li class="nav-item dropdown mr-2">
                        <a class="nav-link dropdown-toggle pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            الالعاب
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow-sm">
                            <router-link to="/Puzzles" class="dropdown-item">الغاز</router-link>
                            <router-link to="/Arcade" class="dropdown-item">تحدي</router-link>
                            <router-link to="/Strategy" class="dropdown-item">أكشن</router-link>
                            <router-link to="/Adventure" class="dropdown-item">مغامرة</router-link>
                            <router-link to="/Sports" class="dropdown-item">رياضية</router-link>
                        </div>
                    </li>
                    <li class="nav-item dropdown mr-2">
                        <router-link to="/" class="nav-link  pr-0"  role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            الرئيسية
                        </router-link>
                    </li>
                    <router-link to="/" class="navbar-brand mr-2" style="margin-right: 5px !important;"><img style="height: 70px;width: 50px;" src="../../assets/image/mtn_logo.png"  alt=""></router-link>
                </ul>
            </div>
        </nav>


</template>

<script>
import { ref } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
import { HTTPMTN } from '@/Api/http-mtn';
import { watchEffect } from '@vue/runtime-core';
import { useCookie } from 'vue-cookie-next'
import { useToast } from "vue-toastification";
export default {
   name: 'AppHeader',
    setup() {
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();
        const cookie = useCookie()
        const showHeader = ref(true);
        const search = ref([]);
        watchEffect(() => {
        //   if (cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("MINTUES") > 0) {
        //         HTTPMTN.post(`CheckSubscribtion.php?msisdn=${cookie.getCookie("msisdn")}&service_code=9188`).then((res) => {
        //             if (res.data.MINTUES > 0 ) {
        //                 return;
        //             } else {
        //                 cookie.removeCookie("message");
        //                 cookie.removeCookie("msisdn");
        //                 cookie.removeCookie("MINTUES");
        //             }
        //         });
        //         showHeader.value = true;
        //     } else {
        //        showHeader.value = false;
        //     }

        //     if (cookie.isCookieAvailable("phone")) {
        //         HTTPMTN.post(`CheckSubscribtion.php?msisdn=${cookie.getCookie("phone")}&service_code=9188`).then((res) => {
        //             if (res.data.MINTUES  > 0 ) {
        //                 cookie.setCookie('message', res.data.message, { expire: 60 * res.data.MINTUES, })
        //                 cookie.setCookie('msisdn', cookie.getCookie("phone"), { expire: 60 * res.data.MINTUES, })
        //                 cookie.setCookie('MINTUES', res.data.MINTUES, { expire: 60 * res.data.MINTUES,})
        //                 toast.success("تم تسجيل دخولك استمتع بتجربة فريدة", { timeout: 2000 });
        //                 cookie.removeCookie("phone");
        //                 showHeader.value = true;
        //             } else {
        //                 cookie.removeCookie("message");
        //                 cookie.removeCookie("msisdn");
        //                 cookie.removeCookie("MINTUES");
        //                 showHeader.value = false;
        //             }
        //         });
                
        //     } 
        });  

        const gotoSpay = () => {
            router.push({ path: "/" })
            // window.open("http://la3bttna.com/MTN_LandingPage_APIs/subscribe.php?portalId=5", "_self")
        }

        return { showHeader, gotoSpay };
    },

}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>